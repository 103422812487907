import * as React from 'react';
import qs from 'qs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function FacebookCallback() {
  const query = qs.parse(window.location.search);
  const [accessToken, setAccessToken] = React.useState(null);
  const navigate = useNavigate()

  React.useEffect(() => {
    function onGetAccessToken() {
      axios.request({
        url: `https://graph.facebook.com/v20.0/oauth/access_token?${qs.stringify({
          client_id: process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID,
          redirect_uri: `${process.env.REACT_APP_FE_URL}/shortdrama/connect/facebook/callback`,
          client_secret: "b0f60dca1c2fb8d6f23b7290f58b139e",
          code: query['?code']
        }, { encodeValuesOnly: true })}`,
        method: 'GET'
      }).then(res => {
        setAccessToken(res.data);

      }).catch(err => err);
    }
    onGetAccessToken();
  }, [query]);

  React.useEffect(() => {
    if (accessToken) {
      navigate(`/shortdrama/deleteaccount?facebook_code=${accessToken.access_token}`)
    }
  }, [accessToken])

  return (
    <div className='max-w-[1024px] m-auto'>ok</div>
  );
}