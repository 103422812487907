import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex flex-col space-y-4 justify-center items-center">
        <div className="text-5xl font-bold text-red-500 drop-shadow-lg shadow-red-400">
          Error: 404 - Not Found.
        </div>
        <div className="text-3xl font-bold italic">
          The page you are looking for does not exist.
        </div>
        <Link to={`/shortdrama/deleteaccount`}>
          <button className="border px-2 rounded-lg bg-green-500 text-white font-bold shadow-lg shadow-green-400 transition duration-500 hover:bg-white hover:text-green-400">
            Back to Delete Account Page
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
