import React from "react";

const LoadingScreen = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white opacity-60 flex justify-center items-center">
      <div className="size-20 bg-blue-400 rounded-full flex justify-center items-center relative">
        <div className="size-16 bg-white rounded-full animate-spin">
          <div className="absolute left-1/2 -translate-y-1/2 top-1/2 h-3 bg-white w-10"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
