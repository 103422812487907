import React from "react";
import { cn } from "../../lib/utils";

const ButtonLogin = ({ label, action, icon, className, ...props }) => {
  return (
    <button
      className={cn([
        "flex flex-row justify-center items-center sm:space-x-5 space-x-2 w-full",
        className,
      ])}
      {...props}
      onClick={() => action && action()}
    >
      {icon}
      <span className="font-bold select-none">{label}</span>
    </button>
  );
};

export default ButtonLogin;
