import clsx from "clsx";
import qs from "qs";

export function cn([...value]) {
  return clsx(...value);
}

export function getImageUrl({ url, defaultImage = "" }) {
  return url
    ? url.includes("https") || url.includes("http")
      ? url
      : `${process.env.REACT_APP_BE_URL}/${url}`
    : defaultImage;
}

export function createQuery(value) {
  return qs.stringify(value, { encodeValuesOnly: true });
}
