import React from "react";

const BlankPage = () => {
  return (
    <div className="flex justify-center items-center text-3xl font-bold fixed top-0 right-0 left-0 bottom-0">
      <div>Welcome</div>
    </div>
  );
};

export default BlankPage;
