import React from "react";

const GoogleIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon_google">
        <path
          id="è·¯å¾ 2204"
          d="M11.2201 4.24145C12.8238 4.21706 14.3748 4.80274 15.5474 5.87548L18.7061 2.85265C16.6803 0.989321 13.997 -0.0327311 11.2191 0.000799694C9.14448 0.000246175 7.11055 0.565377 5.34482 1.63304C3.57908 2.7007 2.15114 4.22879 1.2207 6.0464L4.84004 8.80073C5.28282 7.48183 6.1367 6.33239 7.28132 5.51442C8.42594 4.69645 9.80361 4.25122 11.2201 4.24145Z"
          fill="#EA4335"
        ></path>
        <path
          id="è·¯å¾ 2205"
          d="M21.9914 11.2547C22.0049 10.5006 21.9254 9.7476 21.7544 9.01221H11.248V13.0829H17.4167C17.2997 13.7968 17.0367 14.4802 16.6433 15.0919C16.25 15.7036 15.7346 16.2311 15.128 16.6426L18.6601 19.3235C19.76 18.2816 20.6248 17.0251 21.1986 15.6353C21.7724 14.2455 22.0424 12.7532 21.9914 11.2547Z"
          fill="#4285F4"
        ></path>
        <path
          id="è·¯å¾ 2206"
          d="M4.82384 13.1666C4.57896 12.4682 4.45281 11.7351 4.45042 10.9966C4.45478 10.2593 4.57628 9.52722 4.81056 8.8266L1.19123 6.07227C0.406678 7.59955 -0.00195312 9.28572 -0.00195312 10.9957C-0.00195312 12.7056 0.406678 14.3918 1.19123 15.9191L4.82384 13.1666Z"
          fill="#FBBC05"
        ></path>
        <path
          id="è·¯å¾ 2207"
          d="M11.2176 21.9958C13.9437 22.0715 16.5957 21.1207 18.6287 19.3389L15.0966 16.658C13.9515 17.4103 12.5955 17.7938 11.2176 17.7551C9.80244 17.7468 8.42588 17.3018 7.28324 16.4835C6.14059 15.6651 5.28988 14.5148 4.85176 13.1958L1.23242 15.9502C2.15994 17.7673 3.58536 19.2953 5.34896 20.3631C7.11256 21.4309 9.14467 21.9962 11.2176 21.9958Z"
          fill="#34A853"
        ></path>
      </g>
    </svg>
  );
};

export default GoogleIcon;
