import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import TiktokCallback from "./pages/TiktokCallback";
import BlankPage from "./pages/BlankPage";
import NotFound from "./pages/NotFound";
import FacebookCallback from "./pages/FacebookCallback";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <BlankPage />,
  },
  {
    path: "/shortdrama/deleteaccount",
    index: true,
    element: <App />,
  },
  {
    path: "/shortdrama/connect/tiktok/callback",
    element: <TiktokCallback />,
  },
  {
    path: "/shortdrama/connect/facebook/callback",
    element: <FacebookCallback />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="roboto-regular">
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <RouterProvider router={routers} />
    </GoogleOAuthProvider>
    ,
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
