import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

const TiktokCallback = () => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const navigate = useNavigate();

  const onHaveCode = () => {
    Swal.fire({
      icon: "success",
      title: "Verified Success",
      text: "Successfully authenticated customer information.",
      showConfirmButton: false,
      timer: 2000,
    }).then(() => {
      navigate(`/shortdrama/deleteaccount?tiktok_code=${code}`);
    });
  };

  useEffect(() => {
    if (code) {
      onHaveCode();
    }
  }, [code]);

  return <></>;
};

export default TiktokCallback;
