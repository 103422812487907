import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon_facebook">
        <ellipse
          id="Ellipse 198"
          cx="14"
          cy="14.5"
          rx="11"
          ry="11.5"
          fill="#3E67B5"
        ></ellipse>
        <g id="icon_facebook">
          <g id="Symbols">
            <g id="footer/idle">
              <g id="Icon/facebook">
                <g id="footer-facebook-(1)">
                  <path
                    id="Facebook"
                    d="M25.9994 14.0733C26.009 10.8811 24.7501 7.81572 22.4998 5.55156C20.2494 3.28741 17.1919 2.00989 13.9997 2C10.8075 2.00989 7.75001 3.28741 5.49967 5.55156C3.24933 7.81572 1.99049 10.8811 2.00005 14.0733C1.99341 16.9402 3.00871 19.7156 4.86373 21.9014C6.71875 24.0873 9.29204 25.5404 12.1218 26V17.5627H9.07519V14.0733H12.1218V11.414C12.1218 8.38733 13.9131 6.716 16.655 6.716C17.5551 6.72876 18.4531 6.80786 19.3416 6.95267V9.924H17.8283C17.5696 9.89052 17.3067 9.91563 17.059 9.99747C16.8114 10.0793 16.5853 10.2158 16.3974 10.3968C16.2096 10.5778 16.0649 10.7988 15.974 11.0433C15.8832 11.2878 15.8484 11.5496 15.8723 11.8093V14.076H19.2009L18.6676 17.5653H15.8717V26C18.7025 25.5417 21.2772 24.0891 23.1334 21.9032C24.9897 19.7172 26.0058 16.9411 25.9994 14.0733Z"
                    fill="white"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FacebookIcon;
