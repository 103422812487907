import React from "react";

const AccountNotFound = () => {

  return (
    <div className="flex flex-col justify-center items-center space-y-4 py-8">
      <div className="text-2xl font-bold">Account Not Found.</div>
      <div className="text-xl italic text-center">
        Oops! It seems this account does not exist in our system. Please check
        again.
      </div>
      <a
        href={"/shortdrama/deleteaccount"}
        className="border px-2 hover:text-green-500 hover:bg-white bg-green-400 text-white font-bold rounded-md"
      >
        Reload Page
      </a>
    </div>
  );
};

export default AccountNotFound;
